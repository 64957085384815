<template>
  <b-card>
    <b-row>
      <b-col cols="6">
        <p class="form-label">
          <strong>{{capitalChosenLabel}}</strong>
          <br />
          {{capitalChosenData}}
        </p>
      </b-col>
      <b-col cols="6">
        <p class="form-label">
          <strong>{{capitalPartnersLabel}}</strong>
          <br />
          {{capitalPartnersData}}
        </p>
      </b-col>
      <b-col cols="6">
        <p class="form-label">
          <strong>{{capitalPartnersEffectiveLabel}}</strong>
          <br />
          {{capitalPartnersEffectiveData}}
        </p>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { formatCurrency } from '@/utils/formatterHelper';

export default {
  name: 'ProfileTabsDataCapital',
  components: {},
  props: {
    data: Object,
    clickReport: Boolean,
  },
  computed: {
    notAvailableMsg() {
      return 'N.D.';
    },
    capitalChosenLabel() {
      return 'Capitale Deliberato:';
    },
    capitalChosenData() {
      const balanceSheet = this.balanceDetail(0);
      const value = balanceSheet.totalLiability?.totalShareholdersEquity.totalShareholdersEquityBlock
        .shareCapital.shareCapital;
      return formatCurrency(value, 'it', 'EUR') || this.notAvailableMsg;
    },
    capitalPartnersLabel() {
      return 'Sottoscritto:';
    },
    capitalPartnersData() {
      const balanceSheet = this.balanceDetail(0);
      const value = balanceSheet.totalLiability?.totalShareholdersEquity.totalShareholdersEquityBlock
        .shareCapital.shareCapital;
      return formatCurrency(value, 'it', 'EUR') || this.notAvailableMsg;
    },
    capitalPartnersEffectiveLabel() {
      return 'Versato:';
    },
    capitalPartnersEffectiveData() {
      const balanceSheet = this.balanceDetail(0);
      const shareCapital = balanceSheet.totalLiability?.totalShareholdersEquity.totalShareholdersEquityBlock
        .shareCapital.shareCapital;
      const calledUpCapital = balanceSheet.totalAsset?.receivableFromShareholders
        .receivableFromShareholdersBlock.calledUpCapital;
      if (shareCapital && calledUpCapital) {
        return formatCurrency((shareCapital - calledUpCapital), 'it', 'EUR');
      }
      return formatCurrency(shareCapital, 'it', 'EUR') || this.notAvailableMsg;
    },
  },
  methods: {
    balanceDetail(idx) {
      const report = this.data || {};
      if (report.balances) {
        return report.balances[idx];
      }
      return {};
    },
  },
};
</script>

<style scoped>

</style>
